import React from 'react';
import PropTypes from 'prop-types';
import styles from './styles.module.scss';

const BuildCard = ({ title, description, icon }) => {
  return (
    <div className={styles.card}>
      <div className={styles.icon}>{icon}</div>
      <div className={styles.info}>
        <p className={styles.title}>{title}</p>
        <p className={styles.description}>{description}</p>
      </div>
    </div>
  );
};

BuildCard.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  icon: PropTypes.node
};

export default BuildCard;
