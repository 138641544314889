import React from 'react';
import PropTypes, {object} from 'prop-types';
import styles from './styles.module.scss';
import clsx from 'clsx';


const CirclesShapes = ({ color, stylesProp, classProp }) => {
  return (
    <div
      style={{
        ...stylesProp
      }}
      className={clsx(
        styles.container,
        color === 'primary' ? styles.primary : '',
        color === 'white' ? styles.white : '',
        classProp ? classProp : ''
      )}>
      <div/>
      <div/>
      <div/>
    </div>
  )
};

CirclesShapes.propTypes = {
  color: PropTypes.string,
  stylesProp: object,
  classProp: PropTypes.string
};

export default CirclesShapes;
