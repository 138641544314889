import React from 'react';
import PropTypes from 'prop-types';
import styles from './styles.module.scss';

const CapabilityCard = ({ title, description, icon }) => {
  return (
    <div className={styles.card} >
      <p className={styles.title}>{title}</p>
      <div className={styles.icon}>{icon}</div>
      <p className={styles.description}>{description}</p>
    </div>
  );
};

CapabilityCard.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  icon: PropTypes.node
};

export default CapabilityCard;
