import React from "react";
import Img from "gatsby-image";
import Layout from "../components/Layout/Layout";
import styles from "./how-it-works.module.scss";
import Title from "../components/UI/Title/Title";
import TryNowButton from "../components/TryNowButton/TryNowButton";
import ContactSalesButton from "../components/ContactSalesButton/ContactSalesButton";
import CirclesShapes from "../components/UI/CirclesShapes/CirclesShapes";
import Engagements from "../assets/images/engagements.svg";
import ActionsImg from "../assets/images/actions.svg";
import Impressions from "../assets/images/impressions.svg";
import LinesImg from "../assets/images/bg_lines.svg";
import VideoIcon from "../assets/images/video.svg";
import ImageIcon from "../assets/images/image.svg";
import InfoIcon from "../assets/images/info.svg";
import Section from "../components/Section/Section";
import CapabilityCard from "../components/CapabilityCard/CapabilityCard";
import WideBackground from "../components/UI/WideBackground/WideBackground";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Pagination } from "swiper";
import BuildCard from "../components/BuildCard/BuildCard";
import SEO from "../components/SEO";
import { useSiteImages } from "../hooks/useAllImages";

SwiperCore.use([Pagination]);

const imagesForImport = [
  "hand_with_plus",
  "sharing",
  "sharing_mob",
  "phone_share",
  "phone_share_mob",
  "hand_2",
];

const HowItWorksPage = () => {
  const images = useSiteImages(imagesForImport);

  const EngagementsCard = () => (
    <CapabilityCard
      icon={<Engagements />}
      title="Engage"
      description="The TappShare App empowers your team to spread your content."
    />
  );

  const ImpressionsCard = () => (
    <CapabilityCard
      icon={<Impressions />}
      title="Communicate"
      description="Drag and drop controls allow you to easily create elegant, mobile-optimized content."
    />
  );

  const ActionsCard = () => (
    <CapabilityCard
      icon={<ActionsImg />}
      title="Activate"
      description="Team members can quickly share your content via direct message channels to fill your sales funnel like no other."
    />
  );

  const InfoCard = () => (
    <BuildCard
      title="Information Card"
      description="Add a card with an image, quick information, and a call to action button"
      icon={<InfoIcon />}
    />
  );

  const ImageCard = () => (
    <BuildCard
      title="Image Card"
      description="Add an image card to display different products."
      icon={<ImageIcon />}
    />
  );

  const VideoCard = () => (
    <BuildCard
      title="Video Card"
      description="Embed a video from Youtube or Vimeo to play on this campaign."
      icon={<VideoIcon />}
    />
  );

  return (
    <Layout>
      <SEO title="How it works" />
      <div className={styles.page}>
        <section className={styles.head}>
          <div className={styles.top}>
            <div className={styles.titleContainer}>
              <Title
                title="Easily create shareable content"
                variant="h1"
                weight="extra-bold"
              />
              <Title
                title="TappShare's easy to use dashboard and app allow you to quickly create and share mobile optimized content."
                weight="lighter"
                variant="h4"
                classProp={styles.subTitle}
              />
            </div>
            <div className={styles.imgBox}>
              <Img fluid={images.hand_with_plus} className={styles.img} />
            </div>
          </div>
          <div className={styles.buttons}>
            <TryNowButton shadow="primary-dark" />
            <div className={styles.showOnMobile}>
              {" "}
              <ContactSalesButton size="large" textColor="primary" />
            </div>
            <div className={styles.showOnDesktop}>
              <ContactSalesButton
                iconColor="primary"
                background="white"
                textColor="primary"
                shadow="primary"
              />
            </div>
          </div>
          <div className={styles.lines}>
            <LinesImg />
          </div>
        </section>

        <Section classProps={styles.capabilitiesSection}>
          <CirclesShapes color="white" classProp={styles.circlesShapes} />
          <Title
            title="Using TappShare is simple"
            variant="h3"
            size="large"
            color="white"
            classProp={styles.title}
          />
          <div className={styles.cards}>
            <ImpressionsCard />
            <EngagementsCard />
            <ActionsCard />
          </div>
          <div className={styles.cardsSlider}>
            <Swiper
              spaceBetween={10}
              slidesPerView={1}
              pagination={{
                clickable: true,
                bulletClass: `${styles.swiperBullet} swiper-pagination-bullet`,
                bulletActiveClass: `${styles.swiperBulletActive} swiper-pagination-bullet-active`,
                modifierClass: `${styles.pagination} swiper-pagination`,
              }}
              className={styles.swiperContainer}
            >
              <SwiperSlide>
                <ImpressionsCard />
              </SwiperSlide>
              <SwiperSlide>
                <EngagementsCard />
              </SwiperSlide>
              <SwiperSlide>
                <ActionsCard />
              </SwiperSlide>
            </Swiper>
          </div>
          <WideBackground color="primary" classProp={styles.ellipse} />
        </Section>

        <Section classProps={styles.sharingSection}>
          <div className={styles.titleContainer}>
            <Title title="TappShare makes it easy to share" variant="h3" />
            <Title
              title="Sharing content with your team is easy and sharing for your team is even easier with all the different ways to share."
              variant="text"
              weight="lighter"
              classProp={styles.subTitle}
            />
          </div>
          <Img fluid={images.sharing} className={styles.img} />
          <Img fluid={images.sharing_mob} className={styles.imgMob} />
        </Section>

        <section className={styles.sharingOfflineSection}>
          <div className={styles.titleContainer}>
            <Title
              title="Share your content offline too!"
              color="white"
              variant="h3"
              weight="bold"
              classProp={styles.title}
            />
            <Title
              title="Easily generate a QR code for any campaign for friction-free real-world sharing."
              variant="text"
              color="white"
              weight="lighter"
              classProp={styles.subTitle}
            />
          </div>
          <Img fluid={images.phone_share} className={styles.phone} />
          <Img fluid={images.phone_share_mob} className={styles.phoneMob} />
          <WideBackground color="primary" />
        </section>

        <Section classProps={styles.engageCustomersSection}>
          <div className={styles.titleContainer}>
            <Title
              title="Everything you need to engage your customers"
              variant="h3"
              size="large"
              classProp={styles.title}
            />
            <p className={styles.info}>
              Build campaigns, send team announcements, gamified team
              experience, notes and more!
            </p>
          </div>
          <div className={styles.cards}>
            <InfoCard />
            <ImageCard />
            <VideoCard />
          </div>
          <div className={styles.cardsSlider}>
            <Swiper
              spaceBetween={10}
              slidesPerView={1}
              pagination={{
                clickable: true,
                bulletClass: `${styles.swiperBullet} swiper-pagination-bullet`,
                bulletActiveClass: `${styles.swiperBulletActive} swiper-pagination-bullet-active`,
                modifierClass: `${styles.pagination} swiper-pagination`,
              }}
              className={styles.swiperContainer}
            >
              <SwiperSlide>
                <InfoCard />
              </SwiperSlide>
              <SwiperSlide>
                <ImageCard />
              </SwiperSlide>
              <SwiperSlide>
                <VideoCard />
              </SwiperSlide>
            </Swiper>
          </div>
          <Img fluid={images.hand_2} className={styles.img} />
        </Section>

        <Section classProps={styles.contactSection}>
          <div className={styles.titleContainer}>
            <Title
              title="Try TappShare for free"
              variant="h3"
              weight="extra-bold"
            />
          </div>
          <div className={styles.buttons}>
            <TryNowButton shadow="primary-dark" />
            <div className={styles.showOnMobile}>
              <ContactSalesButton size="large" textColor="primary" />
            </div>
            <div className={styles.showOnDesktop}>
              <ContactSalesButton
                iconColor="primary"
                textColor="primary"
                shadow="primary"
              />
            </div>
          </div>
          <WideBackground color="aliceblue" />
        </Section>
      </div>
    </Layout>
  );
};

export default HowItWorksPage;
